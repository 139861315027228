// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-404{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-404 h1{
    color: #ffcb74;
    font-size: 600%;
    text-align: center;
};

.page-404 h3{
    color: #f6f6f6;
    font-size: 300%;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/public/css/404.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB,CAAA;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".page-404{\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n}\r\n\r\n.page-404 h1{\r\n    color: #ffcb74;\r\n    font-size: 600%;\r\n    text-align: center;\r\n};\r\n\r\n.page-404 h3{\r\n    color: #f6f6f6;\r\n    font-size: 300%;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
