import React from 'react'
import '../public/css/404.css'

function NotFound() {
  return (
    <div className='page-404'>
        <h1>404</h1>
        <h3 style={{color: 'white', fontSize: '300%', textAlign: 'center'}}>NotFound</h3>
    </div>
  )
}

export default NotFound